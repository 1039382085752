<template>
  <div class="smenu">
    <ul class="menu-back">
      <li><a @click="$router.go(-1)"><i class="smenu-icon fas fa-arrow-circle-left fa-fw"></i> Voltar</a></li>
    </ul>
    <ul>
      <page-menu-item label="Clientes" icon="fal fa-book fa-fw fa-fw" :route="{name: 'suporte.clientes'}"/>
    </ul>
  </div>
</template>

<script>
import PageMenuItem from "components/suporte/components/include/PageMenuItem"

export default {
  name: "MenuClientes",
  inject: ['page'],
  components: {
    PageMenuItem,
  }
}
</script>
