<template>
  <li>
    <a @click="link" :class="classes">
      <div class="lbl"><i v-if="icon" class="smenu-icon" :class="icon"></i> {{ label }}</div>
      <div class="count" v-if="count !== 'undefined' && count !== null">
        <span>{{ count }}</span>
      </div>
      <i class="icon-hover fa fa-arrow-alt-circle-right" v-if="iconHover"></i>
    </a>
  </li>
</template>

<script>
export default {
  name: "PageMenuItem",
  inject: ['page'],
  props: {
    name: {type: String},
    label: {type: String},
    icon: {type: String},
    count: {default: null},
    route: {},
    iconHover: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  computed: {
    classes () {
      const css = []
      let name = this.name || this.label.toLowerCase()
      if (this.route && this.route.name) {
        if (this.route.name === this.$route.name) {
          css.push('active')
        }
      } else if (this.page && this.page.menuActive) {
        if (typeof this.page.menuActive['name'] !== 'undefined') {
          if (this.page.menuActive.name === name) {
            css.push('active')
          }
        } else if (this.page.menuActive === name) {
          css.push('active')
        }
      }
      return css
    }
  },
  methods: {
    link () {
      if (this.route) {
        if (this.route.name && this.route.name === this.$route.name) {
          return
        }
        this.$router.push(this.route)
      }
      if (this.name && this.page && this.page.menuActive) {
        this.page.menuActive = {name: this.name, label: this.label}
        this.page.loadQueue && this.page.loadQueue(this.name)
      }
    }
  }
}
</script>
