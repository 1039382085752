<template>
  <div class="smenu">
    <ul>
      <page-menu-item label="Dashboard" icon="fal fa-clipboard-list fa-fw" :route="{name: 'suporte.dashboard'}"/>
      <page-menu-item label="Projetos" icon="fal fa-book fa-fw fa-fw" :route="{name: 'suporte.projetos'}"/>
      <page-menu-item label="Tarefas" icon="fal fa-tasks fa-fw" :route="{name: 'suporte.tarefas'}"/>
<!--      <page-menu-item label="Clientes" icon="fal fa-user fa-fw" :route="{name: 'suporte.clientes'}"/>-->
    </ul>
    <div class="menu-lbl">Conhecimento</div>
    <ul>
      <page-menu-item class="disabled" label="Base de conhecimento" icon="fal fa-database fa-fw"/>
      <page-menu-item class="disabled" label="Relatórios" icon="fal fa-chart-line fa-fw"/>
    </ul>
  </div>
</template>

<script>
import PageMenuItem from "components/suporte/components/include/PageMenuItem"

export default {
  name: "MenuDash",
  inject: ['page'],
  components: {
    PageMenuItem,
  }
}
</script>
