<template>
  <div class="profile">
    <div class="u-img-rounded hoverable pos-rlt profile-worker-image">
      <img v-if="$uloc.auth.session.user && $uloc.auth.session.user.photo" :src="$uloc.auth.session.user.photo" />
      <img v-else src="https://static.suporteleiloes.com/static/default-user-1.png" />
      <div class="camera-hover hidden-nohover" @click="$refs.profileInput.click()" v-if="!loadingPicture">
        <u-icon name="camera" type="fa" />
      </div>
      <div class="camera-hover" v-else>
        <strong>Carregando...</strong>
      </div>
      <input type="file" ref="profileInput" accept="image/*" @change="setFile($event)" v-show="false">
    </div>
    <div class="profile-data">
      <div class="profile-name">{{$uloc.auth.session.user.name}}</div>
      <div class="profile-office" v-if="$uloc.auth.session.user.papeis && $uloc.auth.session.user.papeis.worker && $uloc.auth.session.user.papeis.worker.office">{{$uloc.auth.session.user.papeis.worker.office.name}}</div>
    </div>
  </div>
</template>

<script>
import {fileToBase64} from "@/utils/fileToBase64"
import {updateUserProfilePicture} from "@/domain/usuario/services"
import {LocalStorage} from "uloc-vue"

export default {
  name: "Profile",
  data () {
    return {
      profilePicture: '',
      loadingPicture: false
    }
  },
  methods: {
    setFile (evt) {
      this.loadingPicture = true
      const file = evt.target.files[0]
      setTimeout(() => {
        fileToBase64(file)
            .then(response => {
              this.updateProfilePicture(response)
            })
            .catch(error => {
              alert('Ocorreu um erro na leitura do arquivo')
              this.loadingPicture = false
            })
      }, 50)
    },
    updateProfilePicture (data) {
      updateUserProfilePicture(this.$uloc.auth.session.user.id, {data, filename: 'foto'})
          .then(response => {
            this.profilePicture = response.data.image
            let user = LocalStorage.get.item('auth.user')
            user.photo = response.data.image
            LocalStorage.set('auth.user', user)
            this.$uloc.auth.session.user = user
            this.$uloc.auth.loginByStorage()
            console.log(response.data)
            this.loadingPicture = false
            // @TODO: session não está reativo, por isto não atualiza a foto. Verificar
          })
          .catch(error => {
            alert('Ocorreu um erro ao alterar a foto de usuário')
            console.log(error)
            this.loadingPicture = false
          })
    }
  }
}
</script>
